<template>
  <div>
    <div class="position-button-dashboard" v-if="verify">
      <span v-if="dark">
        <v-icon
            small
            class="mr-2 btn-change-dark"
            @click="btnDark()"
        >
            far fa-sun

        </v-icon>
      </span>
        <span v-else>
        <v-icon
            small
            class="mr-2 btn-change-dark"
            @click="btnDark()"
        >
               far fa-moon
        </v-icon>
      </span>
    </div>
    <div class="position-button" v-else>
    <span v-if="dark">
      <v-icon
          small
          class="mr-2 btn-change-dark"
          @click="btnDark()"
      >
          far fa-sun

      </v-icon>
    </span>
      <span v-else>
      <v-icon
          small
          class="mr-2 btn-change-dark"
          @click="btnDark()"
      >
             far fa-moon
      </v-icon>
    </span>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ButtonDark",
  data() {
    return {
      dark: false

    }
  },
  props: {
    verify: Boolean
  },
  async created() {
    await this.verifyDark()

  },
  methods: {
    ...mapActions("general", ['setThemeColor']),
    async verifyDark() {
      const theme = this.getDark;
      if (theme === true) {
        this.dark = true
      } else {
        this.dark = false
      }
    },
    async btnDark() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      await this.setThemeColor(this.$vuetify.theme.dark)
      await this.verifyDark()
    }
  },
  computed:{
    ...mapGetters('general', ['getDark'])
  }
}
</script>

<style lang="scss">
.position-button {
  display: block;
  width: 70px;
  height: 70px;
  color: #fff;
  position: fixed;
  right: 24px;
  top: 0px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  z-index: 999;
}

.position-button-dashboard {
  display: block;
  width: 70px;
  height: 70px;
  color: #fff;
  position: fixed;
  right: 300px;
  top: 1px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  z-index: 999;
}

.btn-change-dark {
  font-size: 25px !important;
  color: var(--v-secondary-base) !important;
}

</style>
