<template>
  <v-app>
    <slot />
    <ButtonDark :verify="false"/>
  </v-app>
</template>

<script>
import ButtonDark from "../components/General/ButtonDark";
export default {
  name: "AppLayoutDefault",
  components:{
    ButtonDark
  }
}
</script>

<style scoped>

</style>
